import React, { useMemo } from "react";
import { styled } from "goober";

import { readPath } from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import LoLDefinition, { GAME_SYMBOL_LOL } from "@/game-lol/definition.mjs";
import ArrowIcon from "@/inline-assets/lol-overlay-skill-order-arrow.svg";
import { useOverlaySettings } from "@/library/actions.mjs";
import { OverlayContainer } from "@/library/SimulatedOverlays.style.jsx";

function createSkillOrderConfig(overlay) {
  const {
    features: [
      {
        items: [
          {
            select: { key: skillOrderSelectKey },
          },
        ],
      },
    ],
  } = overlay;

  const SIMULATED_SKILL_ORDER_CONFIG = {
    selectKey: skillOrderSelectKey,
  } as const;

  return SIMULATED_SKILL_ORDER_CONFIG;
}

const SimulatedSkillOrderOverlay = () => {
  const settings = useOverlaySettings(GAME_SYMBOL_LOL);
  const displayOption = useMemo(() => {
    if (!settings) return;
    const fixture = createSkillOrderConfig(
      LoLDefinition.overlays.skillRecommended,
    );
    return readPath(fixture.selectKey, settings);
  }, [settings]);

  return (
    <OverlayContainer className="flex flex-end flex-grow">
      <div className="relative flex">
        <img
          src={`${appURLs.CDN}/blitz/ui/img/settings/lolOverlay/recommendedSkillOrder/in-game-skill-ui.webp`}
          alt="lol-minimap"
        />
        <OverlayGoldArrow className={displayOption}>
          <ArrowIcon />
        </OverlayGoldArrow>
        <OverlayGoldBox
          src={`${appURLs.CDN}/blitz/ui/img/settings/lolOverlay/recommendedSkillOrder/skill-box.webp`}
          alt="gold-box"
          className={displayOption}
        />
      </div>
    </OverlayContainer>
  );
};

const OverlayGoldBox = styled("img")`
  position: absolute;
  top: -115.6px;
  left: 33.3px;
  opacity: 1;
  &.animated {
    animation: blink 1s infinite ease-out;
    @keyframes blink {
      0% {
      }
      50% {
        opacity: 0.6;
      }
      100% {
      }
    }
  }
`;

const OverlayGoldArrow = styled("div")`
  position: absolute;
  top: -28px;
  left: 161.9px;
  &.animated {
    animation: bounce 1s infinite ease-out;
    @keyframes bounce {
      0% {
      }
      50% {
        transform: translateY(-5px);
      }
      100% {
      }
    }
  }
`;

export default SimulatedSkillOrderOverlay;
